import { Flex, Tooltip } from '@mantine/core'

import { ThatchProStatus } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

import { SvgIcon, SvgIconType } from '../shared/image/SvgIcon'
import { Typography } from '../shared/text/Typography'

const data = {
  [ThatchProStatus.newTalent]: {
    label: 'New Talent',
    icon: SvgIconType.NEW_TALENT_ICON,
    tooltip: 'Has not been vetted by Thatch Pro Team.Meets Thatch’s basic quality standards.',
    color: '#DCDCDC',
  },
  [ThatchProStatus.newPro]: {
    label: 'New Pro',
    icon: SvgIconType.NEW_PRO_ICON,
    tooltip: 'Hand-vetted by Thatch Pro team. Shows strong potential to deliver quality service.',
    color: 'appMint.0',
  },
  [ThatchProStatus.risingPro]: {
    label: 'Rising Pro',
    icon: SvgIconType.RISING_PRO_ICON,
    tooltip: 'Hand-vetted by Thatch Pro team. Has proven ability to deliver quality service.',
    color: 'appPink.0',
  },
  [ThatchProStatus.topPro]: {
    label: 'Top Pro',
    icon: SvgIconType.TOP_PRO_ICON,
    tooltip: 'Top 1%. Hand-vetted by Thatch Pro team.  Delivers very high quality service.',
    color: 'appIce.0',
  },
}

const SellerBadge = ({ type, hideText }: { type?: ThatchProStatus; hideText?: boolean }) => {
  const { isMobileScreen } = useScreenSize()

  if (!type || type === ThatchProStatus.none) {
    return null
  }

  const { label, icon, tooltip, color } = data[type]

  return (
    <Tooltip
      label={<Typography variant="caption">{[tooltip]}</Typography>}
      withArrow
      position="top-end"
      arrowOffset={138}
      arrowSize={12}
      maw={288}
      multiline
      sx={{
        textAlign: 'center',
        padding: 8,
        borderRadius: 5,
        backgroundColor: 'black',
        marginTop: 8,
      }}
    >
      <Flex
        align="center"
        gap={6}
        sx={{ width: 'min-content', borderRadius: 30 }}
      >
        <SvgIcon type={icon} />
        {!hideText && (
          <Typography
            variant="tag"
            sx={{ whiteSpace: 'nowrap', fontSize: '15px !important' }}
          >
            {label}
          </Typography>
        )}
      </Flex>
    </Tooltip>
  )
}

export default SellerBadge
