import { Flex, Modal } from '@mantine/core'

import { getVideoUrl } from '~/components/shared/video'
import Player from '~/components/video/Player'
import { useScreenSize } from '~/hooks'

interface IntroVideoModalProps {
  opened: boolean
  setOpened: (value: boolean) => void
  videoUrl: string
}
export const IntroVideoModal: React.FC<IntroVideoModalProps> = ({
  opened,
  setOpened,
  videoUrl,
}) => {
  const { isMobileScreen } = useScreenSize()

  return (
    <Modal
      fullScreen={isMobileScreen}
      centered
      closeOnClickOutside={true}
      opened={opened}
      closeButtonProps={{
        iconSize: 24,
        style: {
          position: 'absolute',
          width: 40,
          height: 40,
          borderRadius: '50%',
          left: 8,
          top: 8,
          zIndex: 10,
          color: 'white',
          border: '1px solid black',
          backgroundColor: 'rgba(0,0,0,0.5)',
        },
      }}
      onClose={() => setOpened(false)}
      styles={{ header: { marginBottom: 0, padding: 0, height: 0 }, body: { paddingRight: 0 } }}
      size="auto"
    >
      <Flex
        justify="center"
        style={{ height: 'min-content', overflow: 'hidden' }}
      >
        <Player
          width={isMobileScreen ? '100%' : 'auto'}
          height={isMobileScreen ? '100vh' : '80vh'}
          url={getVideoUrl(videoUrl)}
          playing={true}
          initFullscreen={isMobileScreen}
        />
      </Flex>
    </Modal>
  )
}
