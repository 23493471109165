import config from '~/thatch-config'

export const getVideoUrl = (url: string) => {
  if (url.startsWith('https')) {
    return url
  }
  const base = config.cdnDisabled
    ? config.imageEndpoint
    : config.cdnEndpoint ?? config.imageEndpoint
  return `${base}/images/${url}`
}
