import { Flex } from '@mantine/core'
import { useMemo } from 'react'

import { ThatchLink } from '~/components/shared/ThatchLink'
import { Typography } from '~/components/shared/text/Typography'
import { SellerService, ServiceType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

export const maxWidth = 824

const ServicePriceTag = ({
  type,
  price,
  username,
}: {
  username: string
  type: ServiceType
  price: number
}) => {
  const title =
    type === ServiceType.consultation
      ? 'Consultation Call'
      : ServiceType.itinerary === type
      ? 'Custom Itinerary'
      : 'Custom Guide'

  return (
    <ThatchLink
      to={`/seller/services/${type}/${username}`}
      isOpenInNewWindow
    >
      <Flex
        bg="appPaper.0"
        align="center"
        px={12}
        py={8}
        sx={{ borderRadius: 20 }}
      >
        <Typography
          variant="tag"
          sx={{ whiteSpace: 'nowrap' }}
        >
          ${price} {title}
        </Typography>
      </Flex>
    </ThatchLink>
  )
}

type ServicePriceTagsProps = {
  username: string
  consultation?: SellerService | null
  recs?: SellerService | null
  itinerary?: SellerService | null
}
const ServicePriceTags = ({ username, recs, itinerary, consultation }: ServicePriceTagsProps) => {
  const { isMobileScreen } = useScreenSize()

  const prices = useMemo(() => {
    const consultationPrices = [
      consultation?.base?.price,
      consultation?.standard?.price,
      consultation?.premium?.price,
    ].filter(el => typeof el === 'number') as number[]

    const recsPrices = [recs?.base?.price, recs?.standard?.price, recs?.premium?.price].filter(
      el => typeof el === 'number'
    ) as number[]

    const itineraryPrices = [
      itinerary?.base?.price,
      itinerary?.standard?.price,
      itinerary?.premium?.price,
    ].filter(el => typeof el === 'number') as number[]

    return {
      [ServiceType.consultation]: consultation?.disabled
        ? undefined
        : Math.min(...consultationPrices),
      [ServiceType.recs]: recs?.disabled ? undefined : Math.min(...recsPrices),
      [ServiceType.itinerary]: itinerary?.disabled ? undefined : Math.min(...itineraryPrices),
    }
  }, [])

  return (
    <Flex
      gap={8}
      align="center"
      sx={
        isMobileScreen
          ? {
              overflowX: 'auto',
              width: 'calc(100vw - 32px)',
              paddingBottom: 16,
              marginBottom: -16,
            }
          : {}
      }
    >
      {prices[ServiceType.consultation] && consultation && (
        <ServicePriceTag
          username={`@${username}`}
          type={ServiceType.consultation}
          price={prices[ServiceType.consultation]}
        />
      )}
      {prices[ServiceType.recs] && recs && (
        <ServicePriceTag
          username={`@${username}`}
          type={ServiceType.recs}
          price={prices[ServiceType.recs]}
        />
      )}
      {prices[ServiceType.itinerary] && itinerary && (
        <ServicePriceTag
          username={`@${username}`}
          type={ServiceType.itinerary}
          price={prices[ServiceType.itinerary]}
        />
      )}
    </Flex>
  )
}

export default ServicePriceTags
