import { Box, Flex, Image, Space } from '@mantine/core'
import { IconPlayerPlay } from '@tabler/icons'
import { useState } from 'react'

import { ReviewCardSummary } from '~/components/reviews/ReviewSummary'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { ThatchLink } from '~/components/shared/ThatchLink'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { getImageUrl } from '~/components/shared/image/helpers'
import { Typography } from '~/components/shared/text/Typography'
import { ProfileSummaryType, ServiceType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

import SellerBadge from '../SellerBadge'
import { IntroVideoModal } from './IntroVideoModal'
import ServicePriceTags from './ServicePriceTags'

export const maxWidth = 824

const ServicePriceTag = ({
  type,
  price,
  username,
}: {
  username: string
  type: ServiceType
  price: number
}) => {
  const title =
    type === ServiceType.consultation
      ? 'Consultation Call'
      : ServiceType.itinerary === type
        ? 'Custom Itinerary'
        : 'Custom Guide'

  return (
    <ThatchLink
      to={`/seller/services/${type}/${username}`}
      isOpenInNewWindow
    >
      <Flex
        bg="appPaper.0"
        align="center"
        px={12}
        py={8}
        sx={{ borderRadius: 20 }}
      >
        <Typography
          variant="tag"
          sx={{ whiteSpace: 'nowrap' }}
        >
          ${price} {title}
        </Typography>
      </Flex>
    </ThatchLink>
  )
}
type ProfileCardProps = {
  data: ProfileSummaryType
  sellerSearchView?: boolean
}
const ProfileCard = ({
  data: {
    username,
    sellerPhoto,
    photo,
    sellerIntroVideo,
    name,
    bio,
    home,
    travelTypes,
    recs,
    itinerary,
    consultation,
    proStatus,
    rating,
    reviews,
  },
  sellerSearchView,
}: ProfileCardProps) => {
  const { isMobileScreen } = useScreenSize()
  const [showIntroVideoModal, setShowIntroVideoModal] = useState(false)

  return (
    <>
      <Flex
        direction="column"
        gap={isMobileScreen ? 13 : 8}
        justify="end"
      >
        <Flex
          p={sellerSearchView ? 0 : 24}
          maw={maxWidth}
          sx={sellerSearchView ? {} : { border: '1px solid rgba(0, 0, 0, 0.20)' }}
          gap={isMobileScreen ? 10 : 24}
        >
          <Box sx={{ position: 'relative' }}>
            <Image
              src={getImageUrl({
                src: sellerPhoto || photo,
                width: isMobileScreen ? 113 : 168,
              })}
              width={isMobileScreen ? 113 : 168}
              height={isMobileScreen ? 149 : 224}
              fit="cover"
              alt="profile-img"
            />

            {!isMobileScreen && sellerIntroVideo && (
              <ThatchButton
                style={{
                  position: 'absolute',
                  bottom: 12,
                  left: 16,
                  border: '1px solid rgba(0, 0, 0, 0.30)',
                }}
                label="Watch Intro"
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  setShowIntroVideoModal(true)
                }}
                size="extraSmall"
                variant="white"
                leftIcon={
                  <IconPlayerPlay
                    fill="black"
                    height={18}
                    width={18}
                  />
                }
              />
            )}
          </Box>
          <Flex
            w="100%"
            direction="column"
            gap={12}
          >
            <ThatchLink
              to={`/@${username}`}
              isOpenInNewWindow
              underline={false}
            >
              <Flex
                justify={isMobileScreen ? 'start' : 'space-between'}
                align="center"
                gap={8}
              >
                <Typography
                  variant={isMobileScreen ? 'title2' : 'title'}
                  lineClamp={1}
                >
                  {name}
                </Typography>

                <SellerBadge
                  type={proStatus}
                  hideText={isMobileScreen}
                />
              </Flex>

              <Space h={12} />

              <Flex
                direction="column"
                gap={12}
                h="100%"
                sx={{ marginTop: -3.5, flex: 1 }}
              >
                {home && (
                  <Flex
                    align="center"
                    gap={4}
                  >
                    <SvgIcon type={SvgIconType.HOME_ICON_OUTLINE} />
                    <Typography variant="tag">Lives in {home.name}</Typography>
                  </Flex>
                )}

                <Typography
                  mt={!home ? 4 : 0}
                  variant={isMobileScreen ? 'caption' : 'body3'}
                  color="appPlaceholder.0"
                  lineClamp={4}
                  style={{ textOverflow: 'ellipsis' }}
                >
                  {bio}
                </Typography>

                {travelTypes && (
                  <Typography
                    variant="tag"
                    lineClamp={1}
                    style={{ textOverflow: 'ellipsis', lineHeight: '150%' }}
                  >
                    {travelTypes.join('  •  ')}
                  </Typography>
                )}
              </Flex>
            </ThatchLink>

            {!isMobileScreen && sellerSearchView && (
              <Flex
                align="center"
                gap={8}
              >
                <ReviewCardSummary
                  rating={rating}
                  reviews={reviews}
                />
                <ServicePriceTags
                  username={username}
                  consultation={consultation}
                  itinerary={itinerary}
                  recs={recs}
                />
              </Flex>
            )}
          </Flex>
        </Flex>


        {isMobileScreen && sellerSearchView && (
          <Flex
            justify="space-between"
            maw="90vw"
            align="center"
            gap={8}
            sx={{ cursor: 'pointer' }}
          >
            <ReviewCardSummary
              rating={rating}
              reviews={reviews}
            />
            <ServicePriceTags
              username={username}
              consultation={consultation}
              itinerary={itinerary}
              recs={recs}
            />
          </Flex>
        )}

        {!sellerSearchView && (
          <Typography
            variant="body3"
            sx={{ fontStyle: 'italic !important' }}
            color="appPlaceholder.0"
          >
            Preview of your listing in Thatch search results
          </Typography>
        )}
      </Flex >

      {showIntroVideoModal && sellerIntroVideo && (
        <IntroVideoModal
          opened={showIntroVideoModal}
          setOpened={setShowIntroVideoModal}
          videoUrl={sellerIntroVideo}
        />
      )
      }
    </>
  )
}

export default ProfileCard
