import { Box } from '@mantine/core'
import { CSSProperties, useEffect } from 'react'
import ReactPlayer from 'react-player'

interface PlayerProps {
  url: string
  height?: number | string
  width?: number | string
  aspectRatio?: string
  style?: CSSProperties
  containerStyle?: CSSProperties
  playing?: boolean
  initFullscreen?: boolean
}

const Player = ({
  url,
  height,
  width,
  containerStyle,
  playing,
  initFullscreen,
  style = {},
}: PlayerProps) => {
  useEffect(() => {
    if (initFullscreen) {
      setTimeout(() => document.getElementsByTagName('video')?.[0]?.requestFullscreen(), 200)
    }
  }, [])

  return (
    <Box style={containerStyle}>
      <ReactPlayer
        key="intro-video"
        playing={playing}
        style={style}
        url={url}
        height={height}
        width={width}
        playsinline
        pip
        controls
      />
    </Box>
  )
}

export default Player
